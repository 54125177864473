export async function getBarcodeData(barcode, warehouse) {
   let currentLocation = window.location.host;
   let url = 'https://' + currentLocation + '/api/barcode/' + barcode + '&' + warehouse;

   let request = await fetch(url, {
     method: 'GET',
     headers: new Headers({
       'content-type': 'application/json',
       "Accept": "application/json"
     })
   })
   let data = await request.json();

  if (data.status == "ОК" && data.response != null) {
    return data.response
  } else {
    throw new Error("Ошибка: Товар по штрихкоду не найден");
  }
}


export async function getWarehousesArr() {
   let currentLocation = window.location.host;
//   let url = 'http://127.0.0.1:8000' + '/api/warehouses/'
   let url = 'https://' + currentLocation + '/api/warehouses/'

   let request = await fetch(url, {
     method: 'GET',
     headers: new Headers({
       'content-type': 'application/json',
       "Accept": "application/json"
     })
   })

    if (!request.ok) {
      throw new Error("Ошибка: Не удалось получить список складов"); // Генерируем ошибку
    }

    let data = await request.json();

    return data;
}
