import MicroModal from 'micromodal';

const modalTableBody = document.querySelector('.modal__tbody');


export function showModal(data) {
    clearTable();
    createTableData(data);
    MicroModal.show("Product",{
      onShow: modal => console.info(`${modal.id} is shown`),
      onClose: modal => console.info(`${modal.id} is closed`),
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: false,
      awaitOpenAnimation: false,
      awaitCloseAnimation: false,
    });
}

export function createTableData(data) {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const row = document.createElement('tr'); // Создаем строку
      const fieldCell = document.createElement('td'); // Создаем ячейку для поля
      const valueCell = document.createElement('td'); // Создаем ячейку для значения

      fieldCell.textContent = key; // Заполняем ячейку поля
      valueCell.textContent = data[key]; // Заполняем ячейку значения

      row.appendChild(fieldCell); // Добавляем ячейку поля в строку
      row.appendChild(valueCell); // Добавляем ячейку значения в строку

      modalTableBody.appendChild(row); // Добавляем строку в тело таблицы
    }
  }
}

export function clearTable() {
  modalTableBody.innerHTML = '';
}
